<template>
    <div class="lanching_wrap">
        <img class="launcheventimg" src="/media/img/event/launcheventimg_.png" alt="래디오 이벤트">
        <ul class="appdownload_list">
            <li>
                <a>
                    <img @click="goPlayStore" class="launcheventimg4" src="/media/img/event/launcheventimg4.png" alt="구글다운로드">
                </a>
            </li>
            <li>
                <a>
                    <img @click="goAppleStore" class="launcheventimg5" src="/media/img/event/launcheventimg5.png" alt="애플다운로드">
                </a>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
  name: 'LanchEvent',
  data () {
    return {
      htmlele: null
    };
  },
  mounted () {
    this.htmlele = document.querySelector('html');
    this.htmlele.classList.add('launchevent');
  },
  methods: {
    goPlayStore () {
      window.open(`https://play.google.com/store/apps/details?id=com.aplayz.raidio`, '_blank');
    },
    goAppleStore () {
      window.open(`https://itunes.apple.com/app/id6736726668`, '_blank');
    }
  },
  unmounted () {
    this.htmlele.classList.remove('launchevent');
  }
};
</script>
<style scoped>
.lanching_wrap {
  width: 100%;
  height: 100vh;
  background-color: #171717;
}

.launcheventimg{
  max-width: 375px;
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-top: 40px;
}

.launcheventimg1{
  max-width: 175px;
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-top: 75px;
}

.launcheventimg2{
  max-width: 343px;
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-top: 22px;
}

.launcheventimg3{
  max-width: 343px;
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-top: 8px;
}

.appdownload_list{
  display: flex;
  max-width: 276px;
  width: 100%;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 16px;
  margin-bottom: 78px;
}

.launcheventimg4,.launcheventimg5{
  width: 130px;
  height: auto;
}
</style>
